import React from "react"
import { Helmet } from "react-helmet"
import TopBar from "../components/topBar";

import '../styles/App.scss';
import '../styles/OtherGames.scss';
import '../styles/Other.scss';

import bg from '../images/Bg11b.png';
import lava from '../images/lava.gif';
import rightBg from '../images/Icons_Abilities3c.png';
import BotBar from "../components/BotBar";
import { OutboundLink } from "gatsby-plugin-google-analytics";

export default function otherGames() {
  const seo = {
    title: "More Games | Legends of Idleon",
    description: "Check out these other games made by Lava",
    image: "https://www.LegendsOfIdleon.com/share.png",
    url: "https://www.LegendsOfIdleon.com/moreGames",
  }


  return (
    <div className="AppContainer" style={{ backgroundImage: `url(${rightBg})` }}>
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <meta property="og:url" content={seo.url} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:image" content={seo.image} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1024" />
        <meta property="og:image:height" content="500" />
        <meta property="og:image:alt" content="Play Legends of Idleon" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seo.title} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:image" content={seo.image} />


        <link rel="icon" type="image/png" href="/favicon64.png" />
        <link rel="apple-touch-icon" href="/logo192_a.png" />
        <link href="https://fonts.googleapis.com/css2?family=Nunito:wght@600&family=Oxygen&display=swap" rel="stylesheet"></link>

        {/*TODO <script type="application/ld+json">
          {`
              {
                "@context": "https://schema.org",
                "@type": "Organization",
                "url": "https://www.LegendsOfIdleon.com",
                "logo": "https://www.LegendsOfIdleon.com/logo192_a.png",
                "name": "Legends of Idleon",
              }
            `}
        </script> */}
      </Helmet>
      <div className="LeftGradient" />
      <div className="RightGradient" />
      <div className="App">
        <div className="Content2" style={{backgroundImage: `url(${bg})`,backgroundColor: "#090c1c"}}>
            <TopBar/>

          <div className="RealContent" style={{backgroundColor: "#4665b1d2", color: "white"}}>

          <header className="">
            <div className="LavaGamesHeader">
              <h1>Lava's Games</h1>
              <img src={lava} alt="Lava"/>
            </div>
            <p className="LavaGamesText">I've made more games than just Legends of Idleon ya know! You gotta try them out, they're in my list of top 3 games I've ever made</p>
            <OutboundLink target="_blank" rel="noopener noreferrer" className="LavaGameItem" href="https://play.google.com/store/apps/details?id=com.lavaflame.IdleSkilling">
              <img className="LavaGameIcon" src="https://play-lh.googleusercontent.com/SghiPLjQUYEE6sHf0wPKuEGS-KqtHSZEU8NvoUza5uS5hErCPdGxj7vHLdmgH_EHsZQ=s180-rw"/>
              <h2>Idle Skilling</h2>
            </OutboundLink>
            <OutboundLink target="_blank" rel="noopener noreferrer" className="LavaGameItem" href="https://play.google.com/store/apps/details?id=com.lavaflame.chickenrunner">
              <img className="LavaGameIcon" src="https://play-lh.googleusercontent.com/tCAHIjAJIFJldwRBnQCEfqTtck1pKizgOsBz1o0IGxS4d9LUqf-cd864Rba-qP-xLw=s180-rw"/>
              <h2>Chicken Odyssey</h2>
            </OutboundLink>
            <OutboundLink target="_blank" rel="noopener noreferrer" className="LavaGameItem" href="https://play.google.com/store/apps/details?id=com.lavaflame.MMO">
              <img className="LavaGameIcon" src="https://play-lh.googleusercontent.com/mhd9Dn66SanLnxOADDwMkHsP_EIGTfUQQd6hYFWXOO4VOT_Y6D9vNoSh1ML0mXYmpXw=s180-rw"/>
              <h2>Legends of Idleon</h2>
            </OutboundLink>
          </header>
          </div>

          <BotBar/>
        </div>
      </div>
    </div>
  );
}
